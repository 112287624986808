import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Emoji from "../components/emoji"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = ({ location }) => {
  return (
    <Layout location={location}>
      <div></div>
      <div className="flex flex-col items-center font-sans">
        <Seo title="About" />
        <div className="w-full max-w-2xl mx-auto">
          <StaticImage
            src="../images/profile_about.jpg"
            alt="Profile image"
            layout="constrained"
            placeholder="none"
            backgroundColor="transparent"
            imgClassName="rounded-2xl"
            width={800}
          />
        </div>

        <div className="mt-16 sm:mt-24 w-full max-w-2xl mx-auto">
          <h2 className="text-black opacity-90 text-2xl sm:text-2.5xl font-medium">
            About me
          </h2>
          <p className="prose md:prose-lg max-w-none mt-8">
            Hi, I'm <span className="font-semibold">Menghan</span> – a multi-disciplinary product designer dedicated to creating intuitive experiences that solve real-world problems and bring delight to people. 
            <br></br>
            <br></br>
            Currently, I design cross-device experiences for <a href="https://www.google.com/chromebook" target="_blank" rel="noopener noreferrer" className="text-menghan hover:opacity-80">ChromeOS</a> at <span className="font-semibold">Google</span>, crafting seamless experiences that help users be more productive across devices. Previously, I led the design of mobile and web experiences for <span className="font-semibold">200 million</span> users at <a href="https://www.zhihu.com" target="_blank" rel="noopener noreferrer" className="text-menghan hover:opacity-80">Zhihu</a>, driving innovation in user-centric design for large-scale platforms.
            <br></br>
            <br></br>
            When it comes to design, I'm inspired by <a href="https://en.wikipedia.org/wiki/Victor_Papanek" target="_blank" rel="noopener noreferrer" className="text-menghan hover:opacity-80">Victor Papanek</a>'s definition in <i className="font-semibold">Design for the Real World</i>—"Design is the conscious effort to impose meaningful order." This philosophy resonates with me, as I believe great design is rooted in intentionality and a commitment to positive impact. 
            <br></br>
            <br></br>
            Outside of work, I channel my creativity into AI side projects, journaling, drawing, reading, and crafting—always on the lookout for new ways to learn and innovate.
            <br></br>
            <br></br>
            Let's connect at <a href="mailto:hey.menghan@gmail.com" className="text-menghan hover:opacity-80">hey.menghan@gmail.com</a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
